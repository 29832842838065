<template>
  <div>
    <static-fullscreen-card v-show="!idItemShow">
      <template v-slot:title>
        <div>
          <v-select outlined :items="dirList" item-text="text" item-value="value" v-model="type" label="Справочник" hide-details dense class="mt-1" />
        </div>
      </template>

      <template v-slot:actions>
        <v-btn color="primary" @click="$refs.table.updateData()">
          <btn-title icon="fas fa-sync-alt">Обновить</btn-title>
        </v-btn>
        <v-btn v-if="!dirCurrent.readonly && canEdit" color="primary" @click="createNew()" title="Создать элемент">
          <btn-title icon="far fa-plus-square">Создать</btn-title>
        </v-btn>
      </template>
      <portal to="v-main">
        <dir-dialog v-if="showEditDialog" v-model="showEditDialog" :id="idEdit" :api="dirCurrent.api" :dir="dirCurrent" />
      </portal>
      <a-table-f-data2
        v-if="dirCurrent.type == 'data' || false"
        ref="table"
        :dataTable="getData()"
        :model="autoModel || model"
        :useQuery="false"
        :defaults="{
          sort: {},
        }"
      />
      <a-table-f-api v-else ref="table" :api="dirCurrent.api" :model="model" :useQuery="false" :defaults="defaults" @click="onClickRow($event)" />
    </static-fullscreen-card>
    <ViewItem v-if="idItemShow" :idShow="idItemShow" :dirCurrent="dirCurrent" :m="m[dirCurrent.name]" @close="itemShowClose($event)" />
  </div>
</template>

<script>
import { getAccess } from "@/components/mixings";

export default {
  mixins: [getAccess],
  components: {
    dirDialog: () => import("./dialogs/dirDialog"),
    ViewItem: () => import("./views/dirView"),
  },
  data() {
    return {
      idEdit: 0,
      idItemShow: 0,
      showEditDialog: false,
      title: "",
      m: this.$store.getters["config/get"].models.dirs,
      url: "",
      type: null,
      autoModel: null,
    };
  },
  created() {
    this.$root.title = "Справочники";
    if (this.$attrs?.dir) {
      this.type = this.dirList.find((el) => el.name == this.$attrs.dir)?.value || null;
    }
    // this.getDirCurrent();
  },
  computed: {
    defaults() {
      return { sort: { key: "id", order: "ASC" }, filters: this.dirCurrent.filters, paramName: this.dirCurrent.name };
    },
    dirList() {
      let list = this.$store.getters["config/get"].models.dirs.list.filter((el) => {
        return this.getAccess("dirs." + el.name + ".view");
      });
      return list;
    },
    canEdit() {
      return this.getAccess("dirs." + this.dirCurrent.name + ".create") || this.dirCurrent.role.includes(this.$root.profile.role);
    },
    dirCurrent() {
      if (!this.type) this.type = this.dirList[0]?.value;
      return this.dirList.find((el) => el.value == this.type);
    },
    model() {
      const name = this.dirCurrent?.name || null;
      if (!name) return {};
      let model = JSON.parse(JSON.stringify(this.m?.[name].list || []));
      model.forEach((el) => {
        if (el?.sourceList) {
          el.options = this.m[name][el.sourceList];
        }
      });
      return model;
    },
  },
  watch: {
    dirCurrent(t) {
      if (t && this.$attrs?.dir != t.name) this.$router.push({ query: { dir: t.name } });
    },
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    getData() {
      const name = this.dirCurrent.name;
      let tmp;
      let model = this.model;
      let data = [];
      if (this.dirCurrent?.dir) {
        tmp = this.$root.dirs[name];
      } else {
        const path = this.dirCurrent.path.split(".");
        for (let i = 0; i < path.length; i++) {
          const el = path[i];
          if (i === 0) {
            tmp = JSON.parse(JSON.stringify(this.$store.getters["config/get"] || {}));
          }
          tmp = JSON.parse(JSON.stringify(tmp?.[el] || {}));
        }
      }
      let obj = tmp;
      if (true) {
        for (const key in obj) {
          if (Object.hasOwnProperty.call(obj, key)) {
            const el = obj[key];
            const row = { id: key };
            if (!model.find((el) => el.name == "id")) {
              model.push({ name: "id", title: "id", type: "string" });
            }
            for (const k in el) {
              row[k] = el[k];
              if (!model.find((el) => el.name == k)) {
                model.push({ name: k, title: k, type: "string" });
              }
            }
            data.push(row);
          }
        }
      }

      this.autoModel = model;
      let res = data.sort((a, b) => {
        return isNaN(a.id) ? (a.id > b.id) - (b.id > a.id) : parseFloat(a.id) > parseFloat(b.id);
      });
      return res;
    },
    getDirCurrent() {
      //  this.dirCurrent = this.dirList.filter((el) => el.value == this.type);
    },
    onClickRow(d) {
      //if (!this.canEdit ) return;
      if (this.dirCurrent.view) this.idItemShow = d.row.id;
      else this.showEditDialogFun(d.row.id);
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
    itemShowClose() {
      this.loading = true;
      this.$root.title = "Справочники";
      this.$refs.table.updateRowData(this.idItemShow);
      this.loading = !true;
      this.idItemShow = 0;
    },
  },
};
</script>
